import React, { Component } from "react";
import { Link } from "react-router-dom";
import { X } from "react-feather";
import { connect } from "react-redux";
import logo2 from "../header/UpdatedPage/Image/logo2.png";

class SideDrawerMob extends Component {
  render() {
    let drawerClasses = "th-sidedrawer-mob";
    if (this.props.show) {
      drawerClasses = "th-sidedrawer-mob open";
    }

    const { auth } = this.props;

    return (
      <div>
        <div className={drawerClasses}>
          <div className="pt-2 pb-4 ps-3 pe-3">
            <div className="d-flex justify-content-end mb-3">
              <X
                size={28}
                onClick={this.props.clicked}
                className=" mt-1 ml-2 float-right"
                style={{ cursor: "pointer", color: "white" }}
              />
            </div>
            <div>
              <Link to="/">
                <div
                  style={{
                    padding: "50px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src={logo2}
                      alt="logo"
                      className="th-header-logo"
                      style={{ marginRight: "20px" }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#86E7D3",
                        fontSize: "22px",
                        margin: "0px",
                      }}
                    >
                      National
                    </div>
                    <div
                      style={{
                        color: "#86E7D3",
                        fontSize: "22px",
                        margin: "0px",
                      }}
                    >
                      Social
                    </div>
                    <div
                      style={{
                        color: "#86E7D3",
                        fontSize: "22px",
                        margin: "0px",
                      }}
                    >
                      Summit
                    </div>
                    <div style={{ color: "#E7DDB2" }}>22nd-23rd Feb 2025</div>
                  </div>
                </div>
              </Link>

              <Link to="/home" onClick={this.props.clicked} className="th-link">
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  Home
                </p>
              </Link>
              <Link
                to="/about"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  About
                </p>
              </Link>
              <Link
                to="/partners"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  Partners
                </p>
              </Link>
              <Link
                to="/events"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  Events
                </p>
              </Link>
              <Link
                to="/speakers"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  Speakers
                </p>
              </Link>
              <Link
                to="/tickets"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p
                  className="th-sidebar-list-item mb-3"
                  style={{ color: "#DDC38B" }}
                >
                  Tickets
                </p>
              </Link>
              {/* <Link
                to="/contact"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3">Contact</p>
              </Link> */}

              {/* <div className="th-sidebar-list-item1">
                <a
                  href="https://unstop.com/college-fests/national-social-summit-2024-indian-institute-of-technology-iit-roorkee-188070"
                  onClick={this.props.clicked}
                  className="th-link ms-5"
                >
                  <button
                    type="button"
                    className="button-mob"
                    style={{ backgroundColor: "#08856C" }}
                  >
                    Register
                  </button>
                </a>

                {/* <Link
                  to="/login"
                  onClick={this.props.clicked}
                  className="th-link"
                >
                  <button type="button" className="button-mob th-login-btn"><i className="fa-solid fa-user"></i> Login</button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawerMob);
