import React from "react";
import "./ContactUs.css";
import im1 from "./images/logo2.svg";
import logo2 from "./images/Logo2.png";
import im2 from "./images/Vector.png";
import map from "./images/map.png";
import im4 from "./images/Linked-in.png";
import { Link } from "react-router-dom";
import im7 from "./images/Medium.png";
import im5 from "./images/Twitter.png";
import im3 from "./images/Instagram (Traced) (1).png";
import im6 from "./images/Gmail Logo (Traced).png";
import tw from "./images/Twitter (Traced).png";
import yt from "./images/YouTube (Traced).png";
import fb from "./images/Facebook2.png";
import ln from "./images/LinkedIn (Traced).png";

function ContactUs() {
  return (
    <div className="Contact-container">
      <div className="footerCol1">
        <div className="footerLogo">
          <div className="logoImg">
            <img className="logo2" src={logo2} />
          </div>
          <div className="logoContaint">
            <div className="NSS">
              <p>
                National
                <br />
                Social
                <br />
                Summit
              </p>
            </div>
            <div className="date-box">
              <p>22nd-23rd Feb 2025</p>
            </div>
          </div>
        </div>
        <div className="footerText">
          <p>
            It is a platform to come together to address the socio-economic
            problems persisting in the country. It brings together the socially
            active groups present all across the country.
          </p>
        </div>
        <div className="contactusBtn">
          <button
            type="button"
            class="btn-footer"
            onClick={() => {
              window.open("mailto:socialsummit@iitr.ac.in.com");
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="footerCol2">
        <div className="col1">
          <h4>About</h4>
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/speakers">Speakers</a>
          <a href="/partners">Partners</a>
        </div>
        <div className="col1">
          <h4>Information</h4>
          <a href="/">Site Map</a>
          <a href="/">FAQs</a>
          <a href="/">Privacy Policy</a>
          <a href="/">Terms & Conditions</a>
        </div>
        <div className="col-conn">
          <div className="icon-box">
            {/* <div className="icon">
            <a href="https://twitter.com/natsocialsummit?t=_E-S4iDRCvoqO3bGh4u6sw&s=08" ><img className='fabIcon' src={im5} /></a>
          </div>
          <div className="icon">
            <a href="https://www.linkedin.com/company/national-social-summit"> <img className='fabIcon' src={im4} /></a>
          </div> */}
            <div className="icon">
              <a href="https://instagram.com/iitrsocialsummit?utm_medium=copy_link">
                {" "}
                <img className="fabIcon" src={im3} />
              </a>
            </div>
            {/* <div className="icon">
            <a href="https://m.facebook.com/iitrsocialsummit/"> <img className='fabIcon' src={im2} /></a>
          </div> */}
            <div className="icon">
              <a href="mailto:socialsummit@iitr.ac.in.com">
                {" "}
                <img className="fabIcon" src={im6} />
              </a>
            </div>
            <div className="icon">
              <a href="https://medium.com/@socialsummit">
                {" "}
                <img className="fabIcon" src={im7} />
              </a>
            </div>
            <div className="icon">
              <a href="">
                {" "}
                <img className="fabIcon" src={tw} />
              </a>
            </div>
            <div className="icon">
              <a href="">
                {" "}
                <img className="fabIcon" src={yt} />
              </a>
            </div>
            <div className="icon">
              <a href="">
                {" "}
                <img className="fabIcon" src={fb} />
              </a>
            </div>
            <div className="icon">
              <a href="">
                {" "}
                <img className="fabIcon" src={ln} />
              </a>
            </div>
          </div>
          {/* <a href="/">Site Map</a>
          <a href="/">FAQs</a>
          <a href="/">Privacy Policy</a>
          <a href="/">Terms & Conditions</a> */}
        </div>
      </div>
      {/* <div className="footerCol3">
        <img src={map} alt="" style={{ height: "105%", width: "100%" }} />
      </div> */}
    </div>
  );
}

export default ContactUs;
