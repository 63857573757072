import React from "react";
import "./HomeContent.css";
import background from "../../../../components/Home/images/final_background.png";
// import Logo from "../Image/logo.png";
import Logo from "../Image/logo2.png";
import background1 from "../Image/background1.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function Content() {
  return (
    <>
      <div className="home-top-content-wrapper">
        <img src={background1} alt="" className="backimg-head" />

        <div className="home-logo-display">
          <div>
            <img
              src={Logo}
              alt="logo"
              className="th-header-logo"
              style={{ marginRight: "20px" }}
            />
          </div>
          <div className="nss">
            <div style={{ color: "#43766C", fontSize: "22px", margin: "0px" }}>
              National
            </div>
            <div style={{ color: "#43766C", fontSize: "22px", margin: "0px" }}>
              Social
            </div>
            <div style={{ color: "#43766C", fontSize: "22px", margin: "0px" }}>
              Summit
            </div>
            <div style={{ color: "#733833" }}>22nd-23rd Feb 2025</div>
          </div>
        </div>

        <div className="header-bottom">
          <h1 className="header-bottom-heading">
            Connect. Collaborate. Change.{" "}
          </h1>
          <div className="header-bottom-para1">
            <p>National Social Summit 2025</p>

            <p className="header-bottom-line2">22nd-23rd Feb </p>
          </div>
          <p className="header-bottom-para3">
            Welcome to the annual Social Summit of NSS IIT Roorkee with the
            theme Education empowers individuals and communities to make
            sustainable choices to the annual Social Summit of NSS IIT Roorkee
            with the theme Education empowers individuals and communities to
            make sustainable choices. Welcome to the annual Social Summit of NSS
            IIT Roorkee with the theme Education empowers individuals and
            communities to make sustainable choices to the annual Social Summit
            of NSS IIT Roorkee with the theme Education empowers individuals and
            communities to make sustainable choices. Welcome to the annual
            Social Summit of NSS IIT Roorkee with the theme Education empowers
            individuals and communities to make sustainable choices to the
            annual Social Summit of NSS IIT Roorkee with the theme Education
            empowers individuals and communities to make sustainable choices.
          </p>

          <div className="header-bottom-button">
            <NavLink to="/tickets">
              <button id="btn-1">Tickets</button>
            </NavLink>

            {/* <button>Payment</button> */}
          </div>
        </div>
      </div>
    </>
  );
}
